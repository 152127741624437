import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/indexLayout'

const MentionsLegales = () => (
  <Layout>
    <div className="page white-bg">
      <div className="container">
        
        <section className="hero-section">
          <div className="hero-section-66">
            <p className="firstbond-header">
                Mentions légales
            </p>

            <p className="firstbond-header-h2">
              Editeur
            </p>
            <p>
              Le site Firstbond.boutique est produit et édité par Eric Desvignes, entreprise immatriculée au Registre du Commerce et des Sociétés de Montpellier, sous le numéro 800 155 798 R.C.S. Montpellier, dont le siège social est situé à:<br/>
              FIRSTBOND FRANCE<br/>
              Eric Desvignes<br/>
              9, rue Substantion<br/>
              34250 PALAVAS-LES-FLOTS<br/>
              France<br/>
              <br/>
              Siret: 800 155 798 000 21
            </p>

            <p className="firstbond-header-h2">
              Intégrateur HTML, CSS, Gatsby
            </p>
            <p><a href="https://www.stellaire.fr/">Stellaire SAS</a> représentée par Mr WEBER Gilles.</p>

            <p className="firstbond-header-h2">
              Responsable de la rédaction et du contenu
            </p>
            <p>
              Mr Desvignes.
            </p>

            <p className="firstbond-header-h2">
              Hébergement du site
            </p>
            <p>
              Le site est hébergé par Gatsby Cloud.
            </p>

            <p className="firstbond-header-h2">
              Propriété intellectuelle
            </p>
            <p>
              La structure générale, ainsi que les textes, images animées ou fixes, sons, graphismes, documents téléchargeables, bases de données et tout autre élément composant le site sont la propriété exclusive de FIRSTBOND FRANCE.
            </p>

            <p className="firstbond-header-h2">
              Liens hypertextes
            </p>
            <p>
              FIRSTBOND FRANCE autorise tout site Internet à établir un lien pointant vers l'adresse <Link to="/">www.firstbond.boutique</Link> après une simple information préalable du webmestre. Tout lien de ce type interviendra toutefois sous réserve des dispositions légales en vigueur. FIRSTBOND FRANCE se réserve la possibilité, à tout moment, sans préavis et sans avoir à motiver sa décision, d'interdire ces liens.
            </p>

            <p className="firstbond-header-h2">
              Interdiction de mirroring
            </p>
            <p>
              Aucune  mise en ligne du site par un tiers sous une autre URL n'est autorisée.
            </p>

            <br/>
            <br/>
            <br/>

          </div>
        </section>

      </div>

    </div>

  </Layout>
)

export default MentionsLegales
